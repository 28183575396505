import clientConstants from '../constants/clientConstants';

const initialState = {
  creatingNewClient: false,
  clientCreated: false,
  clientUpdated: false,
  searchingClient: false,
  clientSearched: false,
  updatingCreditClient: false,
  clientCreditUpdated: false,
  search: null,
  error: '',
};

export default function client(state = initialState, action) {
  switch (action.type) {
    case clientConstants.CREATE_CLIENT_REQUEST:
      return {
        ...state,
        error: '',
        creatingNewClient: true,
        clientCreated: false,
      };
    case clientConstants.CREATE_CLIENT_SUCCESS:
      return {
        ...state,
        error: '',
        creatingNewClient: false,
        clientCreated: true,
      };
    case clientConstants.CREATE_CLIENT_FAILURE:
      return {
        ...state,
        error: action.error,
        creatingNewClient: false,
        clientCreated: false,
      };
    case clientConstants.UPDATE_CLIENT_REQUEST:
      return {
        ...state,
        updatingNewClient: true,
        clientUpdated: false,
      };
    case clientConstants.UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
        updatingNewClient: false,
        clientUpdated: true,
      };
    case clientConstants.UPDATE_CLIENT_FAILURE:
      return {
        ...state,
        updatingNewClient: false,
        clientUpdated: false,
      };
    case clientConstants.UPDATE_CREDIT_CLIENT_REQUEST:
      return {
        ...state,
        updatingCreditClient: true,
        clientCreditUpdated: false,
      };
    case clientConstants.UPDATE_CREDIT_CLIENT_SUCCESS:
      return {
        ...state,
        updatingCreditClient: false,
        clientCreditUpdated: true,
      };
    case clientConstants.UPDATE_CREDIT_CLIENT_FAILURE:
      return {
        ...state,
        updatingCreditClient: false,
        clientCreditUpdated: false,
      };
      case clientConstants.SEARCH_CLIENT_REQUEST:
        return {
          ...state,
          searchingClient: true,
          clientSearched: false,
          updatingCreditClient: false,
          clientCreditUpdated: false,
        };
      case clientConstants.SEARCH_CLIENT_SUCCESS:
        return {
          ...state,
          search: action.client,
          searchingClient: false,
          clientSearched: true,
        };
      case clientConstants.SEARCH_CLIENT_FAILURE:
        return {
          ...state,
          searchingClient: false,
          clientSearched: false,
        };
    case clientConstants.UNLOAD_CLIENT:
      return initialState;
    default:
      return state;
  }
}
