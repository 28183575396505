/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Loadable from 'react-loadable';
import { Route, Switch } from 'react-router-dom';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import TablePageLoader from './Loader/TablePageLoader';
import LoginLoader from './Loader/LoginLoader';

import Layout from '../Layout/index';

const TableLoading = ({ error, retry, pastDelay }) => {
  if (error) {
    return (
      <div>
        Error!
        <button type="button" onClick={retry}>Retry</button>
      </div>
    );
  } if (pastDelay) {
    return (
      <div style={{ width: '100%' }}>
        <TablePageLoader style={{ width: '100%' }} />
      </div>
    );
  }
  return null;
};

const LoginLoading = ({ error, retry, pastDelay }) => {
  if (error) {
    return (
      <div>
        Error!
        <button type="button" onClick={retry}>Retry</button>
      </div>
    );
  } if (pastDelay) {
    return (
      <div style={{ width: '100%' }}>
        <LoginLoader style={{ width: '100%' }} />
      </div>
    );
  }
  return null;
};

const Login = Loadable({
  loader: () => import('../Login/index'),
  loading: LoginLoading,
});

const Admin = Loadable({
  loader: () => import('../Admin/index'),
  loading: TableLoading,
});

const AdminUser = Loadable({
  loader: () => import('../Admin/Users/index'),
  loading: TableLoading,
});

const AdminClient = Loadable({
  loader: () => import('../Admin/Clients/index'),
  loading: TableLoading,
});

const SearchCard = Loadable({
  loader: () => import('../SearchCard/index'),
  loading: TableLoading,
});

const locationHelper = locationHelperBuilder({});

const userIsNotAuthenticated = connectedRouterRedirect({
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/card',
  allowRedirectBack: false,
  authenticatedSelector: (state) => state.auth.loggedIn !== true,
  wrapperDisplayName: 'UserIsNotAuthenticated',
});

const userIsAuthenticated = connectedRouterRedirect({
  redirectPath: '/',
  authenticatedSelector: (state) => state.auth.loggedIn === true,
  wrapperDisplayName: 'UserIsAuthenticated',
});

const userIsAdmin = connectedRouterRedirect({
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/card',
  allowRedirectBack: false,
  authenticatedSelector: (state) => state.auth.data.user.accountType === 'ADMIN',
  wrapperDisplayName: 'UserIsNotAuthenticated',
});

const wrappedAdminRoutes = () => (
	<>
		<Admin />
    <Switch>
      <Route path='/admin/user' component={AdminUser} />
      <Route exact path='/admin/client' component={AdminClient} />
    </Switch>
	</>
);

const WrappedRoutes = () => (
  <div>
    <Layout>
      <Switch>
        <Route path='/admin/' component={userIsAdmin(wrappedAdminRoutes)} />
        <Route path="/card/" component={SearchCard} />
      </Switch>
    </Layout>
  </div>
);

TableLoading.defaultProps = {
  error: false,
  retry: null,
};

TableLoading.propTypes = {
  error: PropTypes.any,
  retry: PropTypes.any,
  pastDelay: PropTypes.any.isRequired,
};

LoginLoading.defaultProps = {
  error: false,
  retry: null,
};

LoginLoading.propTypes = {
  error: PropTypes.any,
  retry: PropTypes.any,
  pastDelay: PropTypes.any.isRequired,
};

const Router = () => (
  <Switch>
    <Route exact path="/" component={userIsNotAuthenticated(Login)} />
    <Route path="/" component={userIsAuthenticated(WrappedRoutes)} />
  </Switch>
);

export default Router;
