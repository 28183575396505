import React, { useState } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import BuildIcon from '@material-ui/icons/Build';
import LockIcon from '@material-ui/icons/Lock';
import HomeIcon from '@material-ui/icons/Home';
import {
  Container,
  IconButton,
  Typography,
  Toolbar, 
  AppBar,
  Tooltip,
} from '@material-ui/core';
import { connect } from 'react-redux';

import userActions from '../../redux/actions/userActions';
import uiActions from '../../redux/actions/uiActions';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  logo: {
    width: '40px',
    marginRight: '10px',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    paddingLeft: '1em',
    ...theme.mixins.toolbar,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  hide: {
    display: 'none',
  },
}));

const Layout = ({
  pageTitle, logout, children, accountType
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [admin, setAdmin] = useState(false);

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            className={clsx(classes.menuButton, open && classes.hide)}
            onClick={() => setOpen(true)}
          >
            <MenuIcon />
          </IconButton>
          <img src="/psa-logo.svg" alt="logo" className={classes.logo} />
          <Typography variant="h6" className={classes.title}>
            | { pageTitle }
          </Typography>
          {accountType === 'ADMIN' && (
          <>
            {!admin ? (
              <Tooltip title='Panneau admin'>
                <IconButton
                  aria-label="Admin panel"
                  color="inherit"
                  onClick={() => setAdmin(true)}
                  component={Link}
                  to="/admin/client"
                  button
                >
                  <BuildIcon />
                </IconButton>
              </Tooltip>
              ) : (
              <IconButton
                aria-label="Home"
                color="inherit"
                onClick={() => setAdmin(false)}
                component={Link}
                to="/card"
                button
              >
                <HomeIcon />
              </IconButton>
              )
            }
          </>
          )}
          <Tooltip title='Se deconnecter'>
            <IconButton
              onClick={() => logout()}
              aria-label="Logout button"
              color="inherit"
            >
              <LockIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        <Container fixed maxWidth="xl">
          {children}
        </Container>
      </main>
    </div>
  );
};

Layout.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  accountType: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

function mapState(state) {
  const { pageTitle } = state.ui;
  const { accountType } = state.auth.data.user;
  return {
    pageTitle, accountType,
  };
}

const actionCreators = {
  logout: userActions.logout,
  changePageTitle: uiActions.changePageTitle,
};

const connectedLayout = connect(mapState, actionCreators)(Layout);
export default connectedLayout;
