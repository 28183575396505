import uiConstants from '../constants/uiConstants';

let initialState = {};

const currentPage = JSON.parse(sessionStorage.getItem('currentPage'));

if (currentPage) {
  initialState = {
    pageTitle: currentPage,
    importingData: false,
    importedData: false,
    modalData: {
      model: '',
      data: {},
    },
    modal: false,
  };
} else {
  initialState = {
    pageTitle: 'Rechercher carte',
    importingData: false,
    importedData: false,
    modalData: {
      model: '',
      data: {},
    },
    modal: false,
  };
}

export default function ui(state = initialState, action) {
  switch (action.type) {
    case uiConstants.CHANGE_PAGE_TITLE:
      return {
        ...state,
        pageTitle: action.title,
      };
    case uiConstants.HANDLE_MODAL:
      return {
        ...state,
        modal: !state.modal,
        modalData: action.modalData,
      };
    case uiConstants.IMPORT_DATA_REQUEST:
      return {
        ...state,
        importingData: true,
        importedData: false,
      };
    case uiConstants.IMPORT_DATA_SUCCESS:
      return {
        ...state,
        importingData: false,
        importedData: true,
      };
    case uiConstants.IMPORT_DATA_FAILURE:
      return {
        ...state,
        importingData: false,
        importedData: false,
      };
    default:
      return state;
  }
}
