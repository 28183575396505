import axios from 'axios';

import config from '../../config';
import handleResponse from './axios';
import authHeader from '../../helpers/authHeader';

function getDataTable(url) {
  return axios.get(`${config.API_URL}/${url}`,
    {
      headers: {
        Authorization: authHeader(),
      },
    })
    .then(handleResponse)
    .then((data) => data)
    .catch((error) => Promise.reject(error.response.data.message));
}

function createData(url, body) {
  return axios.post(`${config.API_URL}/${url}`,
    body,
    {
      headers: {
        Authorization: authHeader(),
      },
    })
    .then(handleResponse)
    .then((data) => data)
    .catch((error) => Promise.reject(error.response.data.message));
}

function putData(url, body) {
  return axios.put(`${config.API_URL}/${url}`,
    body,
    {
      headers: {
        Authorization: authHeader(),
      },
    })
    .then(handleResponse)
    .then((data) => data)
    .catch((error) => Promise.reject(error.response.data.message));
}

function deleteData(url) {
  return axios.delete(`${config.API_URL}/${url}`,
    {
      headers: {
        Authorization: authHeader(),
      },
    })
    .then(handleResponse)
    .then((data) => data)
    .catch((error) => Promise.reject(error.response.data.message));
}

function restoreData(url) {
  return axios.put(`${config.API_URL}/${url}`, {
    headers: {
      Authorization: authHeader(),
    },
  })
    .then(handleResponse)
    .then((data) => data)
    .catch((error) => Promise.reject(error.response.data.message));
}

const services = {
  createData,
  getDataTable,
  putData,
  deleteData,
  restoreData,
};

export default services;
