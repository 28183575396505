const dev = {
  API_URL: 'http://localhost:3001',
};

const prod = {
  API_URL: 'https://e-mobility.seriali.com/api',
  PUBLIC_URL: 'https://e-mobility.seriali.com/',
};

const config = process.env.NODE_ENV === 'development' ? dev : prod;

export default config;
