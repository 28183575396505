const clientConstants = {
  CREATE_CLIENT_REQUEST: 'CLIENTS_CREATE_CLIENT_REQUEST',
  CREATE_CLIENT_SUCCESS: 'CLIENTS_CREATE_CLIENT_SUCCESS',
  CREATE_CLIENT_FAILURE: 'CLIENTS_CREATE_CLIENT_FAILURE',

  UPDATE_CLIENT_REQUEST: 'CLIENTS_UPDATE_CLIENT_REQUEST',
  UPDATE_CLIENT_SUCCESS: 'CLIENTS_UPDATE_CLIENT_SUCCESS',
  UPDATE_CLIENT_FAILURE: 'CLIENTS_UPDATE_CLIENT_FAILURE',

  UPDATE_CREDIT_CLIENT_REQUEST: 'CLIENTS_UPDATE_CREDIT_CLIENT_REQUEST',
  UPDATE_CREDIT_CLIENT_SUCCESS: 'CLIENTS_UPDATE_CREDIT_CLIENT_SUCCESS',
  UPDATE_CREDIT_CLIENT_FAILURE: 'CLIENTS_UPDATE_CREDIT_CLIENT_FAILURE',

  SEARCH_CLIENT_REQUEST: 'CLIENTS_SEARCH_CLIENT_REQUEST',
  SEARCH_CLIENT_SUCCESS: 'CLIENTS_SEARCH_CLIENT_SUCCESS',
  SEARCH_CLIENT_FAILURE: 'CLIENTS_SEARCH_CLIENT_FAILURE',

  UNLOAD_CLIENT: 'CLIENTS_UNLOAD_CLIENT',
};

export default clientConstants;
